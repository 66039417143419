// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import "jquery"
window.$ = $;
window.jQuery = jQuery;


Rails.start()
Turbolinks.start()
ActiveStorage.start()

// import { Calendar } from '@fullcalendar/core';
// import dayGridPlugin from '@fullcalendar/daygrid';
// window.Calendar = Calendar;
// window.dayGridPlugin = dayGridPlugin;


// CSS
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
// import '@fullcalendar/timegrid/main.css';


// JS
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';


// 月カレンダーでうまく表示できるコード↓
// document.addEventListener('DOMContentLoaded', function() {
//     var calendarEl = document.getElementById('calendar');
//     var events = JSON.parse(calendarEl.getAttribute('data-events'));

//     var calendar = new Calendar(calendarEl, {
//         plugins: [dayGridPlugin],
//         initialView: 'dayGridMonth',
//         events: events,
//         slotMinTime: '08:00',
//         slotMaxTime: '22:00',
//         headerToolbar: {
//             left: 'prev,next today',
//             center: 'title',
//             right: 'timeGridDay,timeGridWeek,dayGridMonth'
//         }
//     });

//     calendar.render();
// });


// タイムグリッドで出すコード
document.addEventListener('turbolinks:load', function() {
    var calendarEl = document.getElementById('calendar');

    // Element existence check
    if (!calendarEl) {
        console.error('Calendar element not found!');
        return;
    }

    var events = JSON.parse(calendarEl.getAttribute('data-events'));

    var calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin, timeGridPlugin], // timeGridPluginを追加
        initialView: 'timeGridWeek', // 初期ビューを適切なものに変更
        events: events,
        slotMinTime: '06:00',
        slotMaxTime: '24:00',
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridDay,timeGridWeek,dayGridMonth'
        },
        eventColor: '#74bce8', // 予約されていないイベントの色
        eventContent: function(arg) {
            // isReservedがtrueの場合、背景色を赤に設定
            if (arg.event.extendedProps.isReserved) {
                arg.backgroundColor = '#e67670';
            }
            // デフォルトのイベントコンテンツを返す
            return { html: arg.event.title };
        },
        eventClick: function(info) {
            window.location.href = info.event.url; // ユーザをイベントのURLにリダイレクトします
        }
    });

    calendar.render();
});
